import React, { useContext } from "react";
import { PhoneNumber } from "./phone_number";
import BorderedContainer from "@components/ui/bordered_container";
import { FlexContainer } from "@components/ui/flex_container";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import { THEME_COLOR } from "@utils/constants";
import { Button } from "@components/ui";
import { useRouter } from "next/router";
import useUser from "@hooks/use_user";
import { AbilityContext } from "@utils/can";

function ItemSummary({ company }) {
    const t = GetTranslatedStrings();
    const user = useUser();
    const router = useRouter();
    const ability = useContext(AbilityContext);
    let addressSearchString = company.street ? company.street : "";
    addressSearchString = addressSearchString.concat(
        " ",
        company.house_number ? company.house_number : ""
    );
    addressSearchString = addressSearchString.concat(" ", company.zip ? company.zip : "");
    let editLink = `/mitt/skraning/${company.id}`;
    if (company.belongs_to != null) {
        editLink = `/mitt/skraning/${company.belongs_to}`;
    }

    const canEditRegistration =
        ability(user).can("update", "registration") ||
        user?.registrations?.map((reg) => reg.id).includes(company.id?.toString?.()) ||
        (user?.external_registrations?.map((reg) => reg.id).includes(company.id?.toString?.()) &&
            company.id != null);

    return (
        <BorderedContainer className="bordered-container-summary mb-3">
            <FlexContainer style={{ margin: "0px 15px 10px 15px" }}>
                {company.logo_link && (
                    <span
                        className="d-flex"
                        style={{
                            height: 100,
                            width: 100,
                            marginRight: 20,
                            justifyContent: "center",
                            backgroundColor: "#fff",
                            borderRadius: "10px",
                        }}
                    >
                        <img
                            src={company.logo_link}
                            alt={company.name + " logo"}
                            style={{
                                maxWidth: 100,
                                maxHeight: 100,
                                width: "auto",
                                height: "auto",
                                alignSelf: "center",
                                alignContent: "center",
                                borderRadius: "10px",
                            }}
                        />
                    </span>
                )}

                <div style={{ flex: 1, marginTop: -10 }}>
                    <div className="company-name">{company.name}</div>
                    {company.slogan && (
                        <div className="torgid-text-secondary job-title">{company.slogan}</div>
                    )}
                    <div className="torgid-text-secondary job-title">{company.job_title}</div>
                    <div className="torgid-text-secondary job-title">{company.text}</div>
                    <Link href={`/?q=${addressSearchString}`}>
                        <a className="underline-on-hover">
                            <div className="torgid-text-secondary company-slogan">
                                {company.street} {company.house_number}
                            </div>
                            {company.city && (
                                <div className="torgid-text-secondary company-slogan">
                                    {company.zip} {company.city}
                                </div>
                            )}
                        </a>
                    </Link>
                    {canEditRegistration && (
                        <Button
                            text={t.edit}
                            onClick={() => {
                                router.push(editLink);
                            }}
                        />
                    )}
                </div>

                <div className="d-flex flex-column justify-content-between align-items-end">
                    <div
                        className="d-flex d-none d-sm-block"
                        style={{ marginTop: -7, marginRight: 5 }}
                    >
                        {company.phones?.map((phone) => {
                            return (
                                <PhoneNumber
                                    key={phone.phone}
                                    phone={phone}
                                    registrationID={company.id}
                                />
                            );
                        })}
                    </div>

                    <DineoutButton link={company?.dineout_link} className="d-none d-sm-block" />

                    <NavigateButton
                        latitude={company.latitude}
                        longitude={company.longitude}
                        className="d-none d-sm-block"
                    />
                </div>
            </FlexContainer>
            <NavigateButton
                latitude={company.latitude}
                longitude={company.longitude}
                className="d-block d-sm-none ms-3 me-3 mb-2"
            />
            <DineoutButton
                link={company?.dineout_link}
                className="d-block d-sm-none ms-3 me-3 mb-2"
            />
        </BorderedContainer>
    );
}

function NavigateButton({ latitude, longitude, className }) {
    const t = GetTranslatedStrings();
    return (
        <>
            {latitude && longitude && (
                <a
                    className={className}
                    target="_blank"
                    href={`https://www.google.com/maps/search/?api=1&query=${latitude}%2C${longitude}`}
                    style={{
                        fontSize: 16,
                        backgroundColor: THEME_COLOR,
                        color: "white",
                        padding: "5px 10px",
                        textAlign: "center",
                        borderRadius: "500px",
                    }}
                    rel="noreferrer"
                >
                    <FontAwesomeIcon icon={faLocationArrow} style={{ marginRight: 10 }} />
                    {t.info_details_navigate}
                </a>
            )}
        </>
    );
}

function DineoutButton({ link, className }) {
    return (
        <>
            {link && (
                <a
                    className={className}
                    target="_blank"
                    href={link}
                    style={{
                        fontSize: "16px",
                        lineHeight: "16px",
                        backgroundColor: "#1c1c1c",
                        color: "white",
                        padding: "5px 10px 5px 5px",
                        textAlign: "center",
                        borderRadius: "500px",
                        verticalAlign: "center",
                    }}
                    rel="noreferrer"
                >
                    <FlexContainer className="flex-grow-1" justifyContent="center">
                        <img
                            src="/img/dineout.svg"
                            style={{ height: 25, paddingRight: 10, verticalAlign: "center" }}
                        />
                        <span
                            style={{
                                alignSelf: "center",
                                verticalAlign: "center",
                                fontSize: "16px",
                                lineHeight: "16px",
                                color: "white",
                            }}
                        >
                            Bóka borð
                        </span>
                    </FlexContainer>
                </a>
            )}
        </>
    );
}

export default ItemSummary;
