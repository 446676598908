import * as React from "react";

function BannedCross(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-10 0 321.076 321.076"
            {...props}
            style={{
                backgroundColor: "#cf0000",
                color: "white",
                padding: 3,

                marginLeft: 7,
                marginRight: 0,
                borderRadius: "4px",
                alignSelf: "center",
            }}
        >
            <path
                d="M98.159 150.539L10.858 237.84c-14.461 14.469-14.461 37.936 0 52.397 14.453 14.453 37.92 14.453 52.372 0l87.309-87.317 87.293 87.317c14.469 14.453 37.92 14.453 52.381 0 14.477-14.469 14.461-37.936 0-52.397l-87.293-87.301 87.301-87.285c14.469-14.477 14.469-37.936 0-52.397-14.469-14.477-37.92-14.477-52.389 0l-87.293 87.309L63.23 10.858c-14.453-14.477-37.92-14.477-52.381 0-14.461 14.461-14.461 37.895 0 52.364l87.31 87.317z"
                fill="currentColor"
            />
        </svg>
    );
}

export default BannedCross;
