import { FlexContainer } from "@components/ui/flex_container";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import moment from "moment";
import React from "react";

function getNextDay(dayINeed) {
    dayINeed = dayINeed + 1;
    const today = moment().isoWeekday();

    // if we haven't yet passed the day of the week that I need:
    if (today <= dayINeed) {
        // then just give me this week's instance of that day
        return moment().isoWeekday(dayINeed).toISOString();
    } else {
        // otherwise, give me *next week's* instance of that same day
        return moment().add(1, "weeks").isoWeekday(dayINeed).toISOString();
    }
}

export default function OpeningHours({ openingHours }) {
    if (!openingHours) {
        return null;
    }
    const t = GetTranslatedStrings();
    let days = [
        { day: t.monday, timestamp: getNextDay(0), times: [] },
        { day: t.tuesday, timestamp: getNextDay(1), times: [] },
        { day: t.wednesday, timestamp: getNextDay(2), times: [] },
        { day: t.thursday, timestamp: getNextDay(3), times: [] },
        { day: t.friday, timestamp: getNextDay(4), times: [] },
        { day: t.saturday, timestamp: getNextDay(5), times: [] },
        { day: t.sunday, timestamp: getNextDay(6), times: [] },
    ];

    if (openingHours?.length > 0) {
        openingHours?.forEach?.((opening) => {
            if (opening.day < days.length) {
                days?.[opening?.day]?.times?.push({
                    open_time: opening.open_time ? opening.open_time : opening.start,
                    close_time: opening.close_time ? opening.close_time : opening.end,
                });
            }
        });
    } else {
        days = [];
    }

    const curr = new Date();
    let today = curr.getDay() - 1;
    if (today < 0) {
        today = 6;
    }
    days.sort((a, b) => {
        return a.timestamp.localeCompare(b.timestamp);
    });

    return (
        <div className="d-flex opening-hours" style={{ marginTop: 5, paddingTop: 5 }}>
            <FlexContainer column className="w-100">
                {days.map((h, i) => {
                    const isOpen =
                        h.times.length > 0 && h.times[0].open_time && h.times[0].close_time;
                    h.times.sort((a, b) => {
                        return a.open_time.localeCompare(b.open_time);
                    });
                    return (
                        <FlexContainer style={{ justifyContent: "space-between", gap: 15 }} key={i}>
                            <div
                                className="px-0 torgid-text-primary"
                                style={{
                                    fontWeight: i == 0 && 600,
                                    paddingBottom: 6,
                                }}
                            >
                                {h.day}
                            </div>
                            <div
                                className="torgid-text-primary"
                                style={{
                                    flex: 1,
                                    textAlign: "right",
                                    fontWeight: i == 0 && 600,
                                }}
                            >
                                {isOpen &&
                                    h.times.map((time, index) => {
                                        return (
                                            <>
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                    {time?.open_time?.substring(0, 5)} -{" "}
                                                    {time?.close_time?.substring(0, 5)}
                                                </span>
                                                {index != h.times.length - 1 && <>, </>}
                                            </>
                                        );
                                    })}
                                {!isOpen && (
                                    <div
                                        className="torgid-text-secondary"
                                        style={{ fontStyle: "italic" }}
                                    >
                                        {t.closed}
                                    </div>
                                )}
                            </div>
                        </FlexContainer>
                    );
                })}
            </FlexContainer>
        </div>
    );
}
