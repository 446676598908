import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import moment from "moment";

export default class OpeningHoursCalculator {
    t = GetTranslatedStrings();
    days = [
        { hours: [] },
        { hours: [] },
        { hours: [] },
        { hours: [] },
        { hours: [] },
        { hours: [] },
        { hours: [] },
    ];

    constructor(activeDays) {
        let mainHours = [...activeDays?.main];
        let specialHours = [];
        if (activeDays?.special) {
            specialHours = [...activeDays?.special];
        }

        if (!activeDays) {
            return;
        }
        mainHours = mainHours.flat();
        mainHours.forEach((activeDay) => {
            if (
                !activeDay.open_time ||
                !activeDay.close_time ||
                activeDay.day >= this.days.length
            ) {
                return;
            }
            this.days?.[activeDay?.day]?.hours?.push({
                start: activeDay.open_time.substring(0, 5),
                end: activeDay.close_time.substring(0, 5),
            });
        });
        if (specialHours) {
            let nextWeekSpecialOpenings = specialHours?.filter((opening) => {
                return moment(opening.date).isBefore(moment().add(7, "days"));
            });

            nextWeekSpecialOpenings?.forEach((opening) => {
                let weekday = moment(opening.date).weekday();
                this.days[weekday] = {
                    hours: [
                        {
                            day: weekday,
                            start: opening.open_time,
                            end: opening.close_time,
                        },
                    ],
                };
            });
        }
    }

    getWeek() {
        return this.days
            .map((day, index) => {
                return day.hours.map((hour) => {
                    return {
                        day: index,
                        open_time: hour.start,
                        close_time: hour.end,
                    };
                });
            })
            .flat();
    }
    dayOfWeek(date, firstDay) {
        let daysOfWeek = {
            sunday: 0,
            monday: 1,
            tuesday: 2,
            wednesday: 3,
            thursday: 4,
            friday: 5,
            saturday: 6,
        };
        firstDay = firstDay || "monday";
        let day = date.getDay() - daysOfWeek[firstDay];
        return day < 0 ? day + 7 : day;
    }

    // 00:00:00
    stringToTime(time) {
        if (!time) {
            return;
        }
        let d = new Date();
        time = time.split(":");
        d.setHours(time[0]);
        d.setMinutes(time[1]);
        return d;
    }

    nowIsBetweenTimes() {
        let now = new Date();
        let day = this.days[this.dayOfWeek(now)];

        if (this.isAlwaysOpen()) {
            return true;
        }

        let isBetween = false;
        day.hours.forEach((hour) => {
            let begin = this.stringToTime(hour.start);
            let end = this.stringToTime(hour.end);
            if (begin > end) {
                end.setDate(end.getDate() + 1);
            }
            if (now >= begin && now <= end) {
                isBetween = true;
            }
        });
        return isBetween;
    }

    getOpenTimeToday() {
        let now = new Date();
        let day = this.days[this.dayOfWeek(now)];

        let openTime = null;
        for (let i = 0; i < day.hours.length; i++) {
            let hour = day.hours[i];
            let begin = this.stringToTime(hour.start);
            if (begin > now) {
                openTime = hour.start;
                break;
            }
        }
        return openTime;
    }

    getCloseTimeToday() {
        let now = new Date();
        let day = this.days[this.dayOfWeek(now)];

        let closeTime = null;
        day.hours.forEach((hour) => {
            let begin = this.stringToTime(hour.start);
            let end = this.stringToTime(hour.end);
            if (begin > end) {
                end.setDate(end.getDate() + 1);
            }
            if (now >= begin && now <= end) {
                closeTime = hour.end;
            }
        });

        return closeTime;
    }

    nowIsBeforeOffer() {
        let now = new Date();
        let day = this.days[this.dayOfWeek(now)];

        let isBeforeOpen = false;
        day.hours.forEach((hour) => {
            let begin = this.stringToTime(hour.start);
            if (now < begin) {
                isBeforeOpen = true;
            }
        });

        return isBeforeOpen;
    }

    isAlwaysOpen() {
        let allDayCount = 0;
        this.days.forEach((day) => {
            day.hours.forEach((hour) => {
                if (
                    hour.start === "00:00" &&
                    (hour.end === "23:59" || hour.end === "24:00" || hour.end === "00:00")
                ) {
                    allDayCount += 1;
                }
            });
        });

        if (allDayCount === 7) {
            return true;
        } else {
            return false;
        }
    }

    hasOpeningHours() {
        let hasOpeningHours = false;
        this.days.forEach((day) => {
            day.hours.forEach((hour) => {
                if (hour.start || hour.end) {
                    hasOpeningHours = true;
                }
            });
        });
        return hasOpeningHours;
    }

    closesMidnightToday() {
        return (
            this.getCloseTimeToday() === "24:00" ||
            this.getCloseTimeToday() === "00:00" ||
            this.getCloseTimeToday() === "23:59"
        );
    }

    getOpenString() {
        let openString;

        if (this.nowIsBetweenTimes()) {
            // It is open.
            openString = `${this.t.opening_hours_open_until} ${this.getCloseTimeToday()}`;

            if (this.closesMidnightToday()) {
                openString = this.t.opening_hours_open_until_midnight;
            }
        } else {
            // It is closed.
            if (this.nowIsBeforeOffer()) {
                openString = `${this.t.opening_hours_opens_at} ${this.getOpenTimeToday()}`;
            } else {
                openString = this.t.opening_hours_closed;
            }
        }

        if (!this.hasOpeningHours()) {
            openString = "";
        }

        if (this.isAlwaysOpen()) {
            openString = this.t.opening_hours_always_open;
        }
        return openString;
    }
}
