import React from "react";
import BannedCross from "@components/icons/BannedIcon";
import { FormatPhone } from "@helpers/Formatters";
import { THEME_COLOR } from "@utils/constants";
import inIframe from "@utils/inIframe";

export function PhoneNumber({ phone, registrationID, fontSize }) {
    const phoneNumber = phone.phone.toString();
    return (
        <div className="d-flex flex-row-reverse flex-grow-1 mb-1">
            {phone.ban ? <BannedCross height={20} width={20} /> : <></>}

            <div className="large-phone-number">
                <a
                    textDecoration="none"
                    href={`tel:${phoneNumber}`}
                    style={{
                        color: THEME_COLOR,
                        fontSize: fontSize,
                        lineHeight: fontSize,
                        whiteSpace: "nowrap",
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (inIframe()) {
                            e.preventDefault();
                            let event = new CustomEvent("phone_number_clicked", {
                                detail: { phone: phoneNumber, compid: registrationID },
                            });
                            parent.window.dispatchEvent(event);
                        }
                        return false;
                    }}
                >
                    {FormatPhone(phoneNumber)}
                </a>
            </div>
        </div>
    );
}
