import {
    faChevronDown,
    faChevronUp,
    faEnvelope,
    faGlobe,
    faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";
import { THEME_COLOR } from "@utils/constants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";
import React, { useContext, useState } from "react";
import { FormatAddress, FormatWebsite } from "@helpers/Formatters";
import Link from "next/link";
import classNames from "classnames";
import { Collapse } from "react-bootstrap";
import { Row } from "../ui/grid";
import styles from "./BranchCell.module.scss";
import BorderedContainer from "@components/ui/bordered_container";
import { FlexContainer } from "@components/ui/flex_container";
import { PhoneNumber } from "@components/pages/info/phone_number";
import OpeningHoursCalculator from "@components/shared/OpeningHoursCalculator";
import OpeningHours from "@components/OpeningHours/OpeningHours";
import IndustryIcon from "@components/icons/IndustryIcon";
import PersonIcon from "@components/icons/PersonIcon";
import useUser from "@hooks/use_user";
import { AbilityContext } from "@utils/can";
import { Button } from "@components/ui";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import moment from "moment";

function BranchCell({ style, parentLogo, branch }) {
    if (!branch) {
        return <></>;
    }
    const router = useRouter();
    const [isOpen, setIsOpen] = useState(false);

    let calc = new OpeningHoursCalculator(branch.opening_hours);

    const hasOpeningHours = branch?.opening_hours?.main?.length > 0;
    const hasEmail = branch?.email?.length > 0;
    const hasWebsite = branch?.website?.length > 0;
    const hasLocation = branch?.latitude && branch?.longitude;
    const isOpenable = hasOpeningHours || hasEmail || hasWebsite || hasLocation;

    const shouldHighlight =
        router.query.id !== undefined && parseInt(router.query.id) === parseInt(branch.id);

    return (
        <BorderedContainer
            key={branch.id}
            id={branch.id}
            className={classNames(styles.container, {
                [styles.highlighted]: shouldHighlight,
                [styles.isOpenable]: isOpenable,
            })}
            style={style}
            onClick={() => {
                if (!isOpenable) {
                    return;
                }
                setIsOpen(!isOpen);
            }}
        >
            <BranchCellHeader
                parentLogo={parentLogo}
                branch={branch}
                isOpen={isOpen}
                isOpenable={isOpenable}
                calc={calc}
            />
            <Collapse in={isOpen}>
                <div className={styles.details}>
                    <BranchCellDetail branch={branch} openingHours={calc.getWeek()} />
                </div>
            </Collapse>
        </BorderedContainer>
    );
}

function BranchCellHeader({ parentLogo, branch, isOpen, isOpenable, calc }) {
    const t = GetTranslatedStrings();
    const hasOpeningHours = calc.getOpenString()?.length !== 0;
    const user = useUser();
    const router = useRouter();
    const ability = useContext(AbilityContext);

    const addressResult = FormatAddress(
        branch?.street,
        branch?.house_number,
        branch?.zip,
        branch?.city
    );

    let logoLink = branch?.logo_link;
    if (!logoLink) {
        logoLink = parentLogo;
    }

    return (
        <FlexContainer
            justifyContent="between"
            alignItems="center"
            className="flex-wrap"
            style={{ minHeight: 48 }}
        >
            <div style={{ display: "flex", gap: 5 }}>
                {isOpenable ? (
                    <>
                        {isOpen ? (
                            <FontAwesomeIcon
                                icon={faChevronUp}
                                style={{
                                    height: 16,
                                    width: 16,
                                    alignSelf: "center",
                                    marginRight: 7,
                                    color: "#aaa",
                                }}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faChevronDown}
                                style={{
                                    height: 16,
                                    width: 16,
                                    alignSelf: "center",
                                    marginRight: 7,
                                    color: "#aaa",
                                }}
                            />
                        )}
                    </>
                ) : (
                    <div style={{ width: 16 + 7, height: 16 }}></div>
                )}
                {logoLink ? (
                    <picture>
                        <img
                            className="search-result-cell-image"
                            src={logoLink}
                            style={{
                                objectFit: "contain",
                                backgroundColor: "white",
                                borderRadius: 10,
                                marginRight: 10,
                            }}
                            width="75px"
                            height="75px"
                        />
                    </picture>
                ) : branch.is_company ? (
                    <IndustryIcon
                        style={{
                            width: 75,
                            height: 75,
                            padding: 5,
                            color: "gray",
                            borderRadius: 0,
                            marginRight: 10,
                        }}
                        width="75px"
                        height="75px"
                    />
                ) : branch.is_company ? (
                    <IndustryIcon
                        style={{
                            width: 75,
                            height: 75,
                            padding: 5,
                            color: "gray",
                            borderRadius: 0,
                            marginRight: 10,
                        }}
                    />
                ) : (
                    <PersonIcon
                        style={{
                            width: 75,
                            height: 75,
                            padding: 5,
                            color: "gray",
                            borderRadius: 0,
                            marginRight: 10,
                        }}
                    />
                )}
                <div className="align-self-center">
                    <div
                        style={{
                            fontSize: 20,
                            fontWeight: 600,
                        }}
                    >
                        {branch.name}
                    </div>
                    <div
                        style={{
                            padding: 0,
                            fontSize: 15,
                            fontStyle: "italic",
                        }}
                    >
                        {branch.text}
                    </div>
                    <div>
                        <Link href={`/?q=${addressResult || ""}`} className="underline-on-hover">
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                {addressResult}
                            </div>
                        </Link>
                    </div>
                    {hasOpeningHours && (
                        <FlexContainer alignItems="center">
                            <div
                                style={{
                                    height: 10,
                                    width: 10,
                                    borderRadius: "50%",
                                    marginRight: 5,
                                    backgroundColor: calc.nowIsBetweenTimes() ? "green" : "red",
                                }}
                            />

                            {calc.getOpenString()}
                        </FlexContainer>
                    )}
                    {ability(user).can("update", "registration") && branch.id != null && (
                        <FlexContainer justifyContent="start" style={{ marginTop: 5 }}>
                            <Button
                                text={t.edit}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    router.push(`/mitt/skraning/${branch.id}`);
                                }}
                            />
                        </FlexContainer>
                    )}
                </div>
            </div>
            <div className="branch-phone-numbers" style={{ paddingLeft: 10, flexGrow: 1 }}>
                {branch.phones?.map((phone) => {
                    return (
                        <PhoneNumber
                            key={phone}
                            registrationID={branch.id}
                            fontSize="22px"
                            phone={phone}
                        />
                    );
                })}
            </div>
        </FlexContainer>
    );
}

function BranchCellDetail({ branch, openingHours }) {
    const t = GetTranslatedStrings();
    return (
        <Row>
            <div className="col-sm-6 ps-4 pt-2">
                {branch.branch_text}
                {branch.opening_hours?.special?.length > 0 && (
                    <div>
                        <div style={{ fontWeight: 600 }}>Sérstakir opnunartímar:</div>
                        {branch.opening_hours?.special
                            ?.sort((a, b) => {
                                return a.date > b.date;
                            })
                            ?.map((day, index) => (
                                <div key={index}>
                                    {moment(day.date).format("dddd, D. MMMM YYYY")}:{" "}
                                    <span style={{ fontWeight: 600 }}>
                                        {day.closed
                                            ? t.closed
                                            : `${day.open_time}-${day.close_time}`}
                                    </span>
                                </div>
                            ))}
                    </div>
                )}

                <OpeningHours openingHours={openingHours} />
            </div>
            <div className="col-sm-6">
                <FlexContainer column justifyContent="center" className="mt-3">
                    {branch?.websites?.map((website) => (
                        <a
                            href={website}
                            target="_blank"
                            key={website}
                            style={{
                                backgroundColor: THEME_COLOR + 11,
                                border: THEME_COLOR + " 1px solid",
                                color: THEME_COLOR,
                                padding: "5px 10px",
                                margin: 0,
                                fontSize: 20,
                                textAlign: "center",
                                borderRadius: "500px",
                                marginBottom: 20,
                                minWidth: 225,
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faGlobe} style={{ marginRight: 5 }} />{" "}
                            {FormatWebsite(website)}
                        </a>
                    ))}
                    {branch?.emails?.map((email) => (
                        <a
                            key={email}
                            href={"mailto:" + email}
                            style={{
                                fontSize: 20,
                                backgroundColor: THEME_COLOR + 11,
                                border: THEME_COLOR + " 1px solid",
                                color: THEME_COLOR,
                                padding: "5px 10px",
                                margin: 0,
                                textAlign: "center",
                                borderRadius: "500px",
                                marginBottom: 20,
                                minWidth: 225,
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 10 }} />
                            {email}
                        </a>
                    ))}
                    {branch.latitude && branch.longitude && (
                        <a
                            href={`https://www.google.com/maps/search/?api=1&query=${branch.latitude}%2C${branch.longitude}`}
                            style={{
                                fontSize: 20,
                                backgroundColor: THEME_COLOR + 11,
                                border: THEME_COLOR + " 1px solid",
                                color: THEME_COLOR,
                                padding: "5px 10px",
                                margin: 0,
                                textAlign: "center",
                                borderRadius: "500px",
                                marginBottom: 20,
                                minWidth: 225,
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <FontAwesomeIcon icon={faLocationArrow} style={{ marginRight: 10 }} />
                            Leiðarlýsing
                        </a>
                    )}
                </FlexContainer>
            </div>
        </Row>
    );
}

export default BranchCell;
