import * as React from "react";

function SkatturinnIcon(props) {
    return (
        <svg viewBox="0 0 52 52" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="#1B1C89" fillRule="nonzero">
                <path d="M29.35 21.263a8.341 8.341 0 015.939 2.457l4.915 4.919a1.107 1.107 0 010 1.564l-.81.814-13.771 13.797-1.485 1.471a1.023 1.023 0 000 1.439l3.745 3.74a1.019 1.019 0 001.434 0l21.387-21.387c.163-.155.271-.36.306-.583a.981.981 0 00-.276-.813L39.28 17.207a11.18 11.18 0 00-7.968-3.292h-3.137c-.814 0-1.595.324-2.172.898l-6.403 6.45h9.75z" />
                <path d="M15.713 28.14l-4.764-4.78a1.325 1.325 0 01-.385-1.062c.038-.32.187-.615.419-.838l.604-.617L26.994 5.444a1.015 1.015 0 000-1.438L23.24.295a1.019 1.019 0 00-1.435 0L.31 21.778a1.032 1.032 0 00-.302.587c-.04.3.063.6.277.814L11.746 34.64a11.168 11.168 0 007.968 3.292h3.09c.814 0 1.594-.323 2.169-.898l6.432-6.432h-9.754a8.387 8.387 0 01-5.938-2.462z" />
            </g>
        </svg>
    );
}

export default SkatturinnIcon;
